<template>
  <v-scale-transition>
    <panel
      :breadcrumbs="breadcrumbs"
      :title="title"
    >
      <v-toolbar flat>
        <v-toolbar-title>Jom Bantu SIM Pack</v-toolbar-title>
        <v-divider
          class="mx-2"
          inset
          vertical
        />
        <tnc
          v-for="item in tncs"
          :key="item.title"
          v-model="item.show"
          :content="item"
        />
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Search"
          single-line
          hide-details
        />
        <v-dialog
          v-model="uploadDialog"
          max-width="500px"
        >
          <template #activator="{ on }">
            <v-btn
              color="primary"
              dark
              class="ml-4 mb-2"
              v-on="on"
            >
              Upload SIM Pack
            </v-btn>
          </template>
          <v-card>
            <v-card-title>
              <span class="headline">{{ formTitle }}</span>
            </v-card-title>

            <v-card-text>
              <v-alert
                :value="true"
                type="info"
              >
                Please ensure that you have <span style="text-decoration: underline;"><strong>enough eRecharge</strong></span> before uploading the simpack!
              </v-alert>
              <v-form
                ref="uploadForm"
                v-model="validSimpack"
                lazy-validation
              >
                <v-container grid-list-md>
                  <v-layout wrap>
                    <v-flex xs12>
                      <v-text-field
                        v-model="newSimpack.iccid"
                        label="Iccid"
                        :rules="[val => !!val || 'Iccid required!']"
                        validate-on-blur
                      />
                    </v-flex>
                    <v-flex xs12>
                      <v-text-field
                        v-model="newSimpack.msisdn"
                        label="Msisdn"
                        :rules="[val => !!val || 'Msisdn required!']"
                        validate-on-blur
                      />
                    </v-flex>
                    <v-flex xs12>
                      <v-select
                        v-model="newSimpack.plan"
                        label="Plan"
                        :items="entrys"
                        :rules="[val => !!val || 'Plan required!']"
                        item-text="name"
                        item-value="code"
                        validate-on-blur
                      >
                        <template
                          slot="item"
                          slot-scope="data"
                        >
                          <v-list-tile-content>
                            <v-list-tile-title v-html="data.item.code" />
                            <v-list-tile-sub-title v-html="data.item.description" />
                          </v-list-tile-content>
                        </template>
                      </v-select>
                    </v-flex>
                    <v-flex xs12>
                      <v-select
                        v-model="newSimpack.student"
                        label="Student"
                        :items="packages"
                        :rules="[val => !!val || 'Student required!']"
                        item-text="student"
                        item-value="id"
                        validate-on-blur
                      >
                        <template
                          slot="item"
                          slot-scope="data"
                        >
                          <v-list-tile-content>
                            <v-list-tile-title v-html="data.item.student" />
                            <v-list-tile-sub-title v-html="data.item.sek" />
                          </v-list-tile-content>
                        </template>
                      </v-select>
                    </v-flex>
                  </v-layout>
                </v-container>
              </v-form>
            </v-card-text>

            <v-alert
              v-model="alert"
              type="error"
            >
              {{ alertMessage }}
            </v-alert>
            <v-card-actions>
              <v-spacer />
              <v-btn
                color="blue darken-1"
                flat
                @click="closeAllDialogs(false)"
              >
                Cancel
              </v-btn>
              <v-btn
                color="blue darken-1"
                flat
                @click="uploadSimpack"
              >
                {{ formTitle }}
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-toolbar>
      <v-data-table
        :headers="headers"
        :items="items"
        :loading="loading"
        :search="search"
        item-key="iccid"
        class="elevation-1"
        disable-initial-sort
      >
        <template #items="props">
          <tr>
            <td class="text-xs-left">
              {{ props.item.entry }}
            </td>
            <td class="text-xs-left">
              {{ props.item.iccid }}
            </td>
            <td class="text-xs-left">
              {{ props.item.msisdn }}
            </td>
            <td class="text-xs-left">
              {{ props.item.plan }}
            </td>
            <td class="text-xs-left">
              {{ props.item.activation }}
            </td>
            <td
              v-if="props.item.status === 'Pending'"
              class="text-xs-left"
            >
              {{ props.item.status }}
            </td>
            <td
              v-else-if="props.item.status === 'Activated'"
              class="text-xs-left success--text"
            >
              {{ props.item.status }}
            </td>
            <td
              v-else-if="props.item.status === 'Expired'"
              class="text-xs-left warning--text"
            >
              {{ props.item.status }}
            </td>
            <td
              v-else
              class="text-xs-left error--text"
            >
              {{ props.item.status }}
            </td>
            <td class="text-xs-left">
              {{ props.item.name }}
            </td>
            <td class="text-xs-left">
              {{ props.item.school }}
            </td>
            <td class="text-xs-center">
              {{ props.item.whitelist }}
            </td>
          </tr>
        </template>
        <template #no-data>
          <v-btn
            color="primary"
            @click="getDealerSim"
          >
            Load dealer SIM packs
          </v-btn>
        </template>
      </v-data-table>
      <v-snackbar
        v-model="snackbar"
        color="success"
        :timeout="5000"
      >
        {{ snackbarText }}
        <v-btn
          dark
          flat
          @click="snackbar = false"
        >
          Close
        </v-btn>
      </v-snackbar>
      <v-snackbar
        v-model="snackbarError"
        color="error"
        :timeout="5000"
      >
        {{ snackbarText }}
        <v-btn
          dark
          flat
          @click="snackbarError = false"
        >
          Close
        </v-btn>
      </v-snackbar>
    </panel>
  </v-scale-transition>
</template>

<script>
import { createGetParams, createPostParams } from '@/rest'

const pageTitle = 'Jom Bantu SIM Pack'

export default {
  components: {
    Panel: () => import(/* webpackChunkName: "widePanel" */ '@/components/WidePanel'),
    Tnc: () => import(/* webpackChunkName: "tncjombantu" */ '@/components/TncJomBantu'),
  },
  data () {
    return {
      title: pageTitle,
      items: [],
      packages: [],
      entrys: [],
      loading: false,
      uploadDialog: false,
      breadcrumbs: [
        {
          text: 'Jom Bantu', disabled: false, to: '/jombantu',
        },
        {
          text: pageTitle, disabled: true,
        },
      ],
      tncs: [],
      tnc: [],
      headers: [
        {
          text: 'Entry',
          align: 'left',
          sortable: true,
          value: 'entry',
        },
        {
          text: 'Iccid',
          align: 'left',
          sortable: true,
          value: 'iccid',
        },
        {
          text: 'Msisdn',
          align: 'left',
          sortable: true,
          value: 'msisdn',
        },
        {
          text: 'Plan',
          align: 'left',
          sortable: true,
          value: 'plan',
        },
        {
          text: 'Activation',
          align: 'left',
          sortable: true,
          value: 'activation',
        },
        {
          text: 'Status',
          align: 'left',
          sortable: true,
          value: 'status',
        },
        {
          text: 'Student',
          align: 'left',
          sortable: true,
          value: 'name',
        },
        {
          text: 'School',
          align: 'left',
          sortable: true,
          value: 'sek',
        },
        {
          text: 'Whitelist',
          align: 'center',
          sortable: true,
          value: 'whitelist',
        },
      ],
      newSimpack: {
        iccid: null,
        msisdn: null,
        plan: null,
        amount: '0.00',
        student: null,
        remark: 'jomBantu',
        id: null,
      },
      defaultSimpack: {
        iccid: null,
        msisdn: null,
        plan: null,
        amount: '0.00',
        student: null,
        remark: 'jomBantu',
        id: null,
      },
      formTitle: 'JomBantu SIM Pack',
      alert: false,
      alertMessage: null,
      snackbar: false,
      snackbarError: false,
      snackbarText: null,
      validSimpack: true,
      search: null,
    }
  },
  watch: {
    uploadDialog (val) {
      if (val) {
        this.$refs.uploadForm.resetValidation()
      } else {
        this.closeAllDialogs(false)
      }
    },
  },

  created: function () {
    this.getDealerSim()
    this.getDealerSimPackages()
    this.getTnc()
  },

  methods: {
    getTnc: function () {
      const params = createGetParams({})
      this.loading = true
      this.$rest.get('getJomBantuTnc.php', params)
        .then(response => {
          this.tncs = response.data
        }).catch(e => {
          this.tncs = []
        })
    },
    getDealerSimPackages () {
      const params = createGetParams({})
      this.loading = true
      this.$rest.get('getJomBantuDealerSimPackages.php', params)
        .then((response) => {
          this.packages = response.data.item
          this.entrys = response.data.entry
          this.loading = false
        }).catch(error => {
          console.log(error)
          this.loading = false
        })
    },

    getDealerSim () {
      const params = createGetParams({})
      this.loading = true
      this.$rest.get('getJomBantuDealerSim.php', params)
        .then((response) => {
          this.items = response.data
          this.loading = false
        }).catch(error => {
          console.log(error)
          this.loading = false
        })
    },

    closeAllDialogs (save) {
      this.uploadDialog = false
      this.alert = false
      setTimeout(() => {
        this.newSimpack = Object.assign({}, this.defaultSimpack)
        save && this.getDealerSim()
      }, 300)
    },

    async uploadSimpack () {
      if (!this.$refs.uploadForm.validate()) {
        return
      }

      const packageCode = this.newSimpack.plan
      const plan = this.entrys.find(plan =>
        plan.code === packageCode,
      )
      this.newSimpack.amount = plan.price

      try {
        const params = createPostParams({
          action: 'uploadDealerSim',
          payload: this.newSimpack,
        })

        const param = createPostParams({
          msisdn: this.newSimpack.msisdn,
          id: this.newSimpack.student,
        })

        await this.$rest.post('postAction.php', params)
        await this.$rest.post('postJomBantu.php', param)

        this.snackbarText = 'You have successfully uploaded a SIM pack.'
        this.snackbar = true
        this.closeAllDialogs(true)
      } catch (error) {
        let message = error.message
        if (error.response) {
          message += ': ' + error.response.data
        }
        this.showError(message)
      }
    },

    showError (message) {
      this.alertMessage = message
      this.alert = true
    },
  },
}
</script>
